// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from './IEnvironment';

export const environment: IEnvironment = {
  production: false,
  debugLog: true,
  enforceInvitations: true,
  prettyUrl: 'https://app.shuffllmedia.com',
  api: 'https://app.shuffllmedia.com:8080/api/v1',
  unleashConfig: {
    url: 'https://us.app.unleash-hosted.com/usbb1021/api/frontend',
    clientKey:
      'shuffll-ec623d619bc6:development.238c8cff017382ca1df6069d8028d3f6d95c3f3a873064490de9eb86',
    appName: 'Shuffll',
    refreshInterval: 3 * 1000,
    cacheInterval: 3 * 1000,
  },
  stripe: {
    publishableKey:
      'pk_live_51JjfJ5JwhYEEanQx4TUudsWLmaXxhYyMd0hVpLsNbVICrYkUcWYUuPP2u4s31JhusePWanySRBNkHgvpa3FON1Ha002rAuaY1u',
  },
  hubspotProxyApi:
    'https://us-central1-shuffll-backend.cloudfunctions.net/Hubspot-Proxy',
  googleFunctions:
    'https://europe-west3-shuffll-beta-signups.cloudfunctions.net',
  mediasoup: 'mediasoup.shuffllmedia.com',
  // mediasoup: 'mediasoup.localhost:4003',
  intercomKey: 'hn18clua',
  mixpanelToken: 'ec0fa41b34138f88a537e2a11d0fe1af',
  trackUserEvents: true,
  baseCdnUrl: 'https://content.shuffll.com',
  googleClientId: '521811392962-bqi728jc3akambuc6qk8nqrn20lkom72',
  version: 'We ♥ Noam - 24.9.2',
};
